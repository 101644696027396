<template>
    <div class="goods-page">
        <div class="tab-bar">
            <van-tabs v-model:active="tabActive" :animated="false" color="#DF5F0B" title-active-color="#DF5F0B">
                <van-tab replace title="新增发货" to="/company/goods/send/edit" />
                <van-tab replace title="发货列表" to="/company/goods/send/list" />
            </van-tabs>
        </div>
        <div class="form-container">
            <div class="form-item">
                <gk-select
                    v-model:active="userPicker.active"
                    v-model:show="userPicker.show"
                    v-model:value="userPicker.value"
                    :columns="userPicker.list"
                    label="资产客户"
                    @confirm="dataForm.user_id = userPicker.active.id"
                />
            </div>
            <div class="form-item">
                <van-cell
                    :value="poleChecked.length > 0 ? `已选择${poleChecked.length}根电杆` : '请选择巡更电杆'"
                    is-link
                    title="发货电杆"
                    @click="openPoleDialog"
                />
            </div>
            <div class="form-item">
                <gk-date-select
                    v-model:active="timeSelect.active"
                    v-model:show="timeSelect.show"
                    v-model:value="timeSelect.value"
                    :max-date="new Date(new Date().setMonth(new Date().getMonth() + 1))"
                    label="发货时间"
                    value-type="date"
                    @confirm="dataForm.time = timeSelect.active + ' 00:00:00'"
                >
                </gk-date-select>
            </div>
            <div class="form-item">
                <van-field v-model="dataForm.remark" input-align="right" label="备注" placeholder="请输入备注" />
            </div>
            <div class="form-item">
                <p class="item-title">产品信息</p>
                <div class="information-list">
                    <div class="information-container">
                        <template v-if="dataForm.information && dataForm.information.length > 0">
                            <div v-for="(item, index) in dataForm.information" class="information-item">
                                <input v-model="item.name" placeholder="信息名称" />
                                <input v-model="item.value" placeholder="信息内容" />
                                <div class="information-buttons">
                                    <van-button type="primary" @click="addInformation">新增</van-button>
                                    <van-button :disabled="index === 0" type="danger" @click="deleteInformation(item)">
                                        删除
                                    </van-button>
                                </div>
                            </div>
                        </template>
                        <van-button v-else block size="small" type="primary" @click="addInformation">
                            新增信息
                        </van-button>
                    </div>
                </div>
            </div>
        </div>
        <van-button block class="from-button" color="#df5f0b" @click="onsubmit">确认发货</van-button>
    </div>

    <van-action-sheet
        v-model:show="poleDialog.show"
        :close-on-click-overlay="false"
        cancel-text="确定"
        title="请选择发货电杆"
    >
        <div class="content">
            <van-cell v-for="(item, index) in poleOptions">
                <!-- 使用 title 插槽来自定义标题 -->
                <template #title>
                    <van-checkbox-group v-model="poleChecked" icon-size="16px">
                        <van-checkbox :name="item.id" shape="square">
                            {{ item.equipment }}
                        </van-checkbox>
                    </van-checkbox-group>
                </template>
            </van-cell>
        </div>
    </van-action-sheet>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { ADD_GOODS_SEND, GET_GOODS_SEND_POLES, GET_GOODS_SEND_USERS } from "@/api/goods";
import usePublic from "@/hooks/usePublic";
import { Toast } from "vant/lib/";

let { $route, $router, $store } = usePublic();

onMounted(() => {
    getOptions();
});

let tabActive = ref(0);

// 表单数据
let dataForm = ref({
    user_id: null,
    time: null,
    remark: null,
    information: []
});

// 选择器
let getOptions = () => {
    GET_GOODS_SEND_USERS({ limit: 10000 }).then((res) => {
        userPicker.value.list = res.data.data.map((item) => {
            return {
                id: item.id,
                text: item.name
            };
        });
        console.log(res.data.data);
    });
    // 发货电杆
    GET_GOODS_SEND_POLES({
        limit: 10000
    }).then((res) => {
        poleOptions.value = res.data.data.list;
        console.log(res.data.data);
    });
};

let userPicker = ref({
    show: false,
    value: "",
    active: {},
    list: []
});
let timeSelect = ref({
    show: false,
    value: "请选择发货时间",
    active: null
});

// 电杆列表
let poleChecked = ref([]);

let poleOptions = ref([]);

let poleDialog = ref({ show: false });

let openPoleDialog = () => {
    poleDialog.value.show = true;
};

// 信息处理
let addInformation = () => {
    dataForm.value.information.push({ name: "", value: "" });
};

let deleteInformation = (val) => {
    dataForm.value.information = dataForm.value.information.filter((item) => {
        return item !== val;
    });
};

let onsubmit = () => {
    let infoArr = dataForm.value.information.map((item) => {
        let obj = {};
        obj[item.name] = item.value;

        return obj;
    });

    console.log(dataForm.value);

    ADD_GOODS_SEND({
        ...dataForm.value,
        goods: poleChecked.value,
        information: infoArr
    }).then((res) => {
        if (res.data.code === 200) {
            Toast({
                type: "success",
                message: "发货成功",
                onClose: () => {
                    location.reload();
                }
            });
        }
    });
};
</script>

<style lang="scss" scoped>
.goods-page {
    min-height: 100vh;
    padding-bottom: 60px;
    background: #f0f0f0;

    .tab-bar {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
    }

    .form-container {
        margin-top: 15px;
        padding: 0 15px;
        background: #ffffff;

        .form-item {
            width: 100%;
            border-bottom: 1px solid #f6f6f6;

            .item-title {
                margin-top: 15px;
                font-size: 14px;
                color: #333333;
            }

            .van-field,
            .van-cell {
                padding-left: 0;
                padding-right: 0;
            }

            :deep(.gk-select),
            :deep(.gk-date-select) {
                .van-cell {
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            .information-list {
                display: flex;
                align-items: center;

                .information-container {
                    padding-bottom: 15px;

                    .information-item {
                        margin-top: 15px;
                        display: flex;
                        align-items: center;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        input {
                            display: block;
                            width: 90px;
                            line-height: 38px;
                            border: 1px solid #f6f6f6;
                            text-align: center;
                            font-size: 12px;
                            color: #333333;
                            margin-right: 7px;
                        }

                        .information-buttons {
                            display: flex;
                            align-items: center;

                            .van-button {
                                margin-left: 5.5px;
                                margin-top: 0;
                                width: 70px;
                                height: 40px;
                            }
                        }
                    }
                }

                .van-button {
                    margin-top: 15px;
                    width: 70px;
                    height: 40px;
                }
            }
        }
    }

    .from-button {
        width: 345px;
        margin: 15px auto 0 auto;
    }
}
</style>
